.drugFreeList {
  padding: 60px 0 96px;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  
  @media screen and (max-width: 992px){
    padding-top: 40px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
  
  &__item {
    @media screen and (max-width: 992px){
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 576px;
    }
  }
  
  &__itemHeader {
    padding: 8px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px 20px 0 0;
    background: $color-blue-main;

    @media screen and (max-width: 992px){
      padding: 14px 19px;
    }
  }
  
  &__headerTitle {
    font: 800 48px/52px $Poppins;
    color: $color-white-simple;

    @media screen and (max-width: 992px){
      font-size: 40px;
      line-height: 44px;
    }
  }
  
  &__headerNumber {
    font: 800 108px/124px $Poppins;
    color: rgba(255, 255, 255, 0.12);

    @media screen and (max-width: 992px){
      font-size: 76px;
      line-height: 80px;
    }
  }
  
  &__itemBody {
    padding: 42px 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 292px;
    background: #F9F9F9;
    border-radius: 0 0 20px 20px;

    @media screen and (max-width: 992px){
      padding: 24px 28px 40px;
      min-height: auto;
    }
  }
  
  &__itemTitle {
    font: 600 32px/44px $Poppins;
    color: $color-black-simple;
    text-align: center;
    margin-bottom: 46px;

    @media screen and (max-width: 992px){
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 24px;
    }
  }
  
  &__btn {
    font: 600 16px/22px $Poppins !important;
    color: #fff;
    padding: 17px 20px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 288px;
    border-radius: 28px !important;
    background: $color-gray !important;

    @media screen and (max-width: 400px){
      width: 240px;
    }

    &Icon {
      margin-right: 10px;
      display: block;
      width: 16px;
      height: 16px;
      background: url("../../../assets/images/icons/download_ic.svg") center no-repeat;
    }
  }
}