.tableList {
  $block: &;
  padding-top: 80px;

  @media screen and (max-width: 768px){
    padding-top: 40px;
  }

  &__title {
    font: 800 24px/32px $Poppins;
    color: $color-black-simple;
    margin-bottom: 10px;

    @media screen and (max-width: 768px){
      margin-bottom: 20px;
    }
  }
  
  &.-customer {
    
  }
  
  .rdt_TableHeadRow {
    .rdt_TableCol {
      font: 700 14px/24px $Poppins;
      color: $color-black;
      white-space: nowrap;
      text-transform: capitalize;
      padding: 6px 16px;
      border-bottom: 2px solid $color-black-simple;
    }
  }
  
  .rdt_TableBody {
    .rdt_TableRow {
      &:nth-of-type(even) {
        background: #FDFDFD;
      }
      &:hover {
        background-color: darken(#FDFDFD, 3%);
      }
      .rdt_TableCell {
        font: 400 14px/24px $Poppins;
        color: $color-black-simple;
        padding: 11px 16px;
        border-bottom: 1px solid #F2F1EF;
      }
    }
  }

  .status {
    font: 400 14px/24px $Poppins;
    display: flex;
    align-items: center;

    &.-active {
      color: #55D41A;

      &:before {
        background: #55D41A;
      }
    }
    &.-archived {
      color: #F84914;

      &:before {
        background: #F84914;
      }
    }

    &:before {
      margin-right: 12px;
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 2px;
    }
  }

  .email {
    color: $color-yellow;
    cursor: pointer;
    text-decoration: none !important;
    &:hover {
      text-decoration: underline !important;
    }
  }

  .editLink {

    &__icon {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      background: url("../../../assets/images/icons/table-edit_ic.svg") center no-repeat;
      cursor: pointer;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border: 2px solid transparent;
        border-radius: 50%;
        transition: .4s ease-in-out;
      }

      &:hover {
        &:before {
          border-color: $color-yellow;
        }
      }
    }
  }
}