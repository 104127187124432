@import "../../../assets/styles/variables";

.help {
  font-family: $Poppins;
  text-transform: none;
  color: $color-text;
  display: flex;
  position: fixed;
  justify-content: flex-end;
  bottom: 30px;
  right: 30px;
}

.helpIcon {
  width: 57px;
  height: 55px;
  z-index: 2;
}

.helpForm {
  display: flex;
  flex-direction: column;
  position: absolute;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.45);
  text-align: left;
  width: 480px;
  bottom: 27px;
  right: 27px;
  padding-left: 47px;
  padding-right: 47px;

  @media screen and (max-width: 480px){
    width: 90%;
    &Input,Textarea {
      width: 100% !important;
    }
  }

  &Spinner {
    position: absolute;
    align-self: center;
    z-index: 9999;
    top: 250px;
  }

  &Header {
    margin-top: 42px;
    font-size: 26px;
    font-weight: bold;
  }

  &SubHeader {
    margin-top: 15px;
    padding-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
    color: #FCA310;
  }

  &ElementWrapper {
    margin-bottom: 15px;
  }

  &Input {
    padding: 10px 20px 10px 20px;
    font-family: $Poppins !important;
    font-size: 14px !important;
    width: 388px;
    border: 1px solid #6C757D;

    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }
    .MuiInputBase-input {
      color: #6C757D;
    }

    &.error {
      font-family: $Poppins !important;
      border-color: $color-error;
    }
  }

  &Textarea {
    width: 388px;
    padding: 10px 15px 10px 20px;
    min-height: 112px;
    border: 1px solid #6C757D;
    background: #FFFFFF;
    resize: none;
    color: #6C757D;
    font-size: 14px !important;
    font-family: $Poppins !important;

    &.error {
      font-family: $Poppins !important;
      border-color: $color-error;
    }
  }

  &Submit {
    display: flex;
    margin-top: 15px !important;
    margin-bottom: 30px;
    justify-content: center !important;

    &>button {
      //font-family: $Montserrat !important;
      width: 212px;
      height: 48px;
    }
  }
}

.MuiInput-input {
  font-family: $Poppins !important;
}