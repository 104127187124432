@import "../../../assets/styles/variables";

.MuiInputBase-input, .inputField__title, .stripeInputField__title, .termsConditions {
  font-family: $Poppins !important;
}

.priceWrapper {
  margin-top: 35px;
  margin-bottom: 30px;
  max-width: 1110px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: flex-start;
  @media screen and (max-width: 970px){
    flex-wrap: wrap;
    justify-content: center;
  }
}

.priceItem {
  position: relative;
  padding: 25px 20px 20px 20px;
  background: #F5F5F5;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.45);
  max-width: 340px;
  min-width: 320px;
  height: 634px;
  color: $color-text;
  border-radius: 12px;

  &>img {
    position: absolute;
    top: 27px;
    right: 17px;
    height: 65px;
  }
  &>h2 {
    margin: 15px 0 0 15px;
    font-family: $Montserrat;
    font-weight: 900;
    font-size: 22px;
  }
  &>h3 {
    margin: 10px 0 0 15px;
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
  &>h4 {
    margin: 15px 0 0 15px;
    font-family: $OpenSans;
    font-size: 14px;
    font-weight: 700;
    &:before {
      content: url('../../../assets/images/icons/triangle_yellow2.svg');
      padding-right: 5px;
      margin-left: -20px;
      vertical-align: -22%;
    }
  }
  &>h5 {
    margin-left: 15px;
    margin-top: 5px;
    font-family: $OpenSans;
    font-size: 14px;
    font-weight: normal;
  }
  &>hr {
    margin: 20px 0 20px 10px;
    height: 5px;
    border: none;
    background-color: $color-yellow;
    width: 225px !important;
  }

  &ReadMore {
    position: absolute;
    font-size: 15px;
    font-weight: bold;
    color: #808080;
    bottom: 115px;
    right: 30px;
    //&:hover {
    //  font-weight: 800;
    //}
  }

  &Footer {
    display: flex;
    position: absolute;
    height: 100px;
    width: 100%;
    margin-left: -20px;
    margin-right: -20px;
    bottom: 0;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: $color-white-simple;
  }

  &Price {
    font-family: $Montserrat;
    font-size: 42px;
    font-weight: 900;
    color: $color-text;
  }

  &.NonReg.-active, &.NonReg:hover  {
    &>.priceItemFooter {
      background-color: $color-yellow;
      &>span {
        color: $color-white-simple;
      }
    }
    &>.priceItemReadMore {
      color: $color-yellow;
    }
  }

  &.RegDoc {
    &>hr {
      background-color: $color-vinous;
    }
    &>h4 {
      &:before {
        content: url('../../../assets/images/icons/triangle_purple2.svg');
      }
    }

    &.-active, &:hover {
      &>.priceItemFooter {
        background-color: $color-vinous;
        &>span {
          color: $color-white-simple;
        }
      }
      &>.priceItemReadMore {
        color: $color-vinous;
      }
    }
  }

  &.AllDocs {
    &>hr {
      background-color: $color-blue-main;
    }
    &>h4 {
      &:before {
        content: url('../../../assets/images/icons/triangle_blue2.svg');
      }
    }

    &.-active, &:hover  {
      &>.priceItemFooter {
        background-color: $color-blue-main;
        &>span {
          color: $color-white-simple;
        }
      }
      &>.priceItemReadMore {
        color: $color-blue-main;
      }
    }
  }
}

.InAddition {
  display: block;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
  margin-top: 80px;
  margin-bottom: 80px;
  &>p:first-child {
    margin-bottom: 5px;
  }

  b {
    font-weight: 900;
  }

  .blueColor {
    color: #1E96FC;
  }
}