@import '../../../../assets/styles/variables';

.introWrapper {
  text-align: center;
}

.introContent {
  font-family: $Poppins;
  font-size: 18px;
  color: $color-text;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;

  h3 {
    font-size: 20px;
    font-weight: 600;
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  b {
    font-weight: 600;
  }

  &__center {
    text-align: center;
  }

  ul {
    margin: 0 0 0 50px;
    padding: 0;
    list-style-type: decimal;
    li {
      margin-bottom: 15px;
      padding-left: 15px;
    }
  }

  .getStarted {
    font-size: 20px;
    font-weight: 600;
  }

}

