@import "../../../assets/styles/variables";

.docsWrapper {
    margin-top: 35px;
    margin-bottom: 65px;
    max-width: 1130px;
}

.docsHeader {
    margin-top: 15px;
    margin-bottom: -10px;
    font-size: 20px;
    font-weight: 400;

    @media screen and (max-width: 552px){
        text-align: center;
    }

    &:before {
        content: url('../../../assets/images/icons/doc_non_reg.svg');
        padding-right: 10px;
        vertical-align: -75%;
    }

    &:is(.RegDoc):before {
        content: url('../../../assets/images/icons/doc_reg.svg');
    }

    &:is(.Res):before {
        content: url('../../../assets/images/icons/res.svg');
    }
}

.docCardsGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 35px;
    gap: 20px;
    justify-content: flex-start;
    @media screen and (max-width: 552px){
        justify-content: center;
    }
}

.docCard {
    position: relative;
    padding: 20px 20px 20px 20px;
    background: #F5F5F5;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.45);
    width: 250px;
    height: 214px;
    border-radius: 12px;
    &>img {
        position: absolute;
        top: 22px;
        right: 17px;
    }
    &>h2 {
        margin-top: 10px;
        margin-left: 5px;
        font-family: $Montserrat;
        font-weight: 900;
        font-size: 16px;
    }
    &>h3 {
        margin-left: 5px;
        margin-top: 5px;
        font-family: $Montserrat;
        font-size: 11px;
        font-weight: 600;
    }
    &>h4 {
        margin-top: 15px;
        font-family: $OpenSans;
        font-size: 10px;
        &:before {
            content: url('../../../assets/images/icons/triangle_yellow.svg');
            padding-right: 5px;
            margin-left: -2px;
            vertical-align: -25%;
        }
    }
    &>h5 {
        margin-left: 15px;
        margin-top: 5px;
        font-family: $OpenSans;
        font-size: 10px;
        font-weight: normal;
    }
    &>hr {
        margin-top: 20px;
        height: 4px;
        border: none;
        background-color: $color-yellow;
        width: 155px !important;
    }
    &Btn {
        display: flex;
        font: 600 12px $Poppins !important;
        color: #fff;
        background: $color-blue-main;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 43px !important;
        border-radius: 4px;
        margin-top: 13px !important;
        padding: 0 5px 0 5px !important;
        &Icon {
            margin-right: 10px;
            display: block;
            width: 16px;
            height: 16px;
            background: url("../../../assets/images/icons/download_ic.svg") center no-repeat;
        }
    }
    &.RegDoc {
        &>hr {
            background-color: $color-vinous;
        }
        &>h4 {
            &:before {
                content: url('../../../assets/images/icons/triangle_purple.svg');
            }
        }
    }
    &.Res {
        &>hr {
            margin-top: 15px;
            background-color: $color-blue-main;
        }
        .docCardBtn {
            margin-top: 20px !important;
        }
        height: 200px;
    }
}

.btnEdit {
    width: 244px;
    font-size: 16px !important;
}


.Affiliate {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -100px;
    text-align: center;
    &Img {
        width: 100%;
    }
    &>button {
        position: absolute;
        bottom: 6%;
        width: 90%;
        max-width: 828px !important;
        height: 48px;
        font-size: 16px !important;
        font-weight: 600 !important;
    }
}

.questionnaireBth {
    text-align: center;
    margin: 100px 0 100px !important;
}

.infoBlock {
    display: flex;
    gap: 7px;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    &:before {
        display: flex;
        justify-content: flex-end;
        align-items: start;
        margin-left: -3px;
        content: url("../../../assets/images/icons/info.svg") ;
    }
}

.infoBlock__text {
    display: flex;
    flex-direction: column;
    gap: 12px
}
