@import '../../../assets/styles/variables';

.stepProgressWrapper {
  display: flex;

  .questionNumber {
    width: 95px;
    margin-top: 21px;

    .selectField {
      .selectField__select {
        height: 45px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    .questionNumber {
      width: 80px;
      margin-bottom: 20px;
    }
  }
}

.linearProgressBar {
  position: relative;

  .progressWrapper {
    padding: 4px;
    border: 1px solid #80808024;
    border-radius: 15px;
  }

  .progressPoints {
    display: flex;
    justify-content: space-between;
    font: 700 18px/20px 'Open Sans', sans-serif;
  }
}

.stepProgress {
  $block: &;
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;

  .MuiLinearProgress-root {
    height: 10px !important;
  }

  .currentNumber {
    span {
      position: absolute;
      top: 25%;
      left: 47%;
      transform: translate(-50% , -50%);
      -webkit-transform: translate(-50%, -50%);
      color: white;
      font: 700 18px/20px 'Open Sans', sans-serif;
    }

    // &__img {
    //   display: inline-block;
    //   position: absolute;
    //   top: -40px;
    //   right: -20px;
    // }

    .currentNumberWrapper {
      display: inline-block;
      position: absolute;
      top: -40px;
      right: -25px;
      width: 50px;
      height: 50px;
      background: url("../../../assets/images/icons/progress-number.svg");
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 24px;
    padding-bottom: 40px;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 132px;

    @media screen and (max-width: 768px) {
      gap: 33px;
    }
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:last-of-type {
      &:before {
        display: none;
      }
    }

    &.state_active {
      &:before {
        background: $color-yellow;
      }
    }

    &:before {
      position: absolute;
      left: 80px;
      bottom: 40px;
      content: '';
      display: block;
      width: 132px;
      height: 4px;
      background: #e5e5e5;

      @media screen and (max-width: 768px) {
        left: 52px;
        bottom: 26px;
        width: 33px;
        height: 3px;
      }
    }
  }

  &__title {
    font: 600 14px/20px $Poppins;
    color: $color-black-simple;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 4px;

    #{$block}__item.state_active & {
      color: $color-yellow;
    }
  }

  &__circleWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border: 5px solid #e5e5e5;
    border-radius: 50%;

    @media screen and (max-width: 768px) {
      width: 52px;
      height: 52px;
      border-width: 2px;
    }

    #{$block}__item.state_active & {
      border: none;
      background: linear-gradient(180deg, #fcca79 0%, #fca311 100%);
    }
    #{$block}__item.state_active + div & {
      border-color: $color-yellow;
    }
  }

  &__icon {
    display: block;
    width: 52px;
    height: 52px;

    @media screen and (max-width: 768px) {
      width: 32px;
      height: 32px;
    }

    path {
      fill: #e5e5e5;

      #{$block}__item.state_active & {
        fill: #fff;
      }
    }
  }
}
