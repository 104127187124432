@import "../../../assets/styles/variables";

.pageContent {
  margin-top: 50px;
}

//.LBackBtn {
//  font: 600 14px $Poppins !important;
//  max-width: 100px;
//  min-width: 100px;
//  height: 35px;
//  margin-left: 40px !important;
//  background-color: $color-blue-main !important;
//}

.pdfDocument {
  margin-top: 30px;
  &Page {
    margin-top: 10px;
  }
}

.iframeContainer {
  margin-top: 30px;
  position: relative;
}

.iframeSpinner {
  //margin-top: 80px;
  top: 20%;
  left: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
}