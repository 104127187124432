@import "../../../assets/styles/variables";

.selectField {
  width: 100%;
  
  &__title {
    font: 700 14px/24px $Poppins;
    color: $color-black;
    text-transform: capitalize;
  }

  &__select {
    border: 1px solid #000000;
    border-radius: 0 !important;
    background: #FFFFFF;
    
    .MuiAutocomplete-input {
      font: 400 normal 16px/24px $Poppins;
      padding: 16px 20px;
      height: 22px !important;

      @media screen and (max-width: 768px){
        font-size: 14px;
        padding: 12px 16px;
        height: 20px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }
    
    em {
      font: 400 normal 16px/24px $Poppins;
      color: rgba($color-black-simple, .3);

      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }

    &.error {
      border-color: $color-error;
    }
  }

  &__item {
    font: 400 normal 16px/24px $Poppins;

    @media screen and (max-width: 768px){
      font-size: 14px;
      padding: 12px 16px;
      height: 20px;
    }
  }
}

.MuiList-root {
  max-height: 220px;
}
