.topBanner {
  display: flex;
  width: 100%;
  align-items: center;
  .title {
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 54px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    @media screen and (max-width: 768px){
      font-size: 24px;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 340px;
    object-fit: cover;

    @media screen and (max-width: 768px){
      height: 128px;
    }
    
    &.-desktop {
      @media screen and (max-width: 576px){
        display: none;
      }
    }
    &.-mobile {
      display: none;
      @media screen and (max-width: 576px){
        display: block;
      }
    }
  }
}