@import '../../../assets/styles/variables';

.progressImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  max-height: 300px;

  span {
    text-align: center;
    text-transform: uppercase;
    font: 700 13px/20px 'Open Sans', sans-serif;
  }
}

.stepContent {
  font-family: $Poppins;
}

.imageAndBtns__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media screen and (max-width: 976px){
    flex-direction: column-reverse;
  }

  .stepContent__btnWrapper {
    margin-top: 0px;
  }
}

.progressContainer {
  .stepProgress {
    margin-left: 2%;
    margin-right: 2%;
  }
}

.question {
  color: $color-black;
}

.answer {
  margin-top: 20px;
  margin-bottom: 20px;

  &__checkboxes {
      display: flex;
      flex-direction: column;
      margin-left: 40px;
      margin-top: 20px;
      margin-bottom: 20px;
      .MuiTypography-root {
        font: 400 14px/16px $Poppins;
        color: $color-black-simple;
      }
  }

  &__memo {
      &.textareaField__textarea {
        width: 600px;
        min-height: 70px !important;
      }
  }

  &.error {
    .MuiRadio-root svg:first-child {
      fill: $color-error;
    }
    .MuiFormControlLabel-label {
      color: $color-error;
    }
    .MuiCheckbox-root > svg > rect:first-child {
      stroke: red;
    }
    .MuiFormControlLabel-label {
      color: $color-error;
    }
    .MuiCheckbox-root > svg > rect:first-child {
      stroke: red;
    }
    .MuiFormControlLabel-label {
      color: $color-error;
    }
    & > textarea{
      border-color: $color-error;
    }
  }
}

.date {
  display: flex;
  div {
    margin-right: 15px;
    width: 160px;
  }
}

.extraQuestion {
  padding-top: 5px;
  padding-left: 30px;

  &Radios {
    display: flex;
    flex-direction: column !important;

    span.MuiCheckbox-colorPrimary.PrivateSwitchBase-root {
      padding: 4px !important;
    }
  }

  .error {
    color: $color-error !important;
    .MuiCheckbox-root {
      color: red;
    }
  }
}

.important {
  color: $color-black;
  margin-bottom: 20px;
  border: 1px solid $color-text;
  padding: 20px 20px 20px 20px;

  &>h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  &>p {
    font-size: 16px;
    text-align: justify;
    margin: 15px 0 0 0;
    &>span {
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 2px;
    }
  }
}

.intruduction {
  margin: 50px 0 50px 0;
}