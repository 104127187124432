@import "../../../assets/styles/variables";

.headerContainer {
  background: $color-blue-main;
}

.headerView {
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;

  @media screen and (max-width: 768px){
    padding: 12px 16px;
  }
}

.headerLogo {
  
  &__img {
    display: block;
    max-width: 128px;

    @media screen and (max-width: 768px){
      max-width: 96px;
    }
  }
}

.headerNavigation {
  $block: &;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  z-index: 1;
  margin-left: auto;

  &__item {
    button {
      display: none;
      width: 100%;
      padding: 18px 20px;
      text-transform: inherit;
      font: 600 14px/28px $Poppins, sans-serif;
    }
  }

  &__item:last-of-type  {
    display: none;
  }
  
  @media screen and (max-width: 768px){
    position: absolute;
    top: -50%;
    left: 0;
    flex-direction: column;
    width: 100%;
    background: #fff;
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.05);
    transition: .4s ease-in-out;

    &__item {
      button {
        display: block;
      }
    }

    &__item:last-of-type  {
        display: block;
    }
  }
  
  &.state_open {
    top: 70px;
  }
  
  &__link {
    position: relative;
    font: 600 16px/28px $Poppins;
    color: #fff !important;
    padding: 26px 0;
    display: block;
    border-top: 2px solid transparent;

    &:last-child {
      padding-right: 40px;
    }

    @media screen and (max-width: 768px){
      font-size: 14px;
      color: #6C757D !important;
      line-height: 20px;
      text-align: center;
      padding: 18px 20px; 
      border-top: none;
      border-bottom: 2px solid transparent;
    }

    &:hover {
      //&:before {
      //  width: 50%;
      //}
      //&:after {
      //  width: 50%;
      //}
    }
    
    &:before {
      position: absolute;
      top: -2px;
      right: 50%;
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $color-blue-main;
      transition: .4s ease-in-out;

      @media screen and (max-width: 768px){
        top: auto;
        bottom: -2px;
      }
    }
    &:after {
      position: absolute;
      top: -2px;
      left: 50%;
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $color-blue-main;
      transition: .4s ease-in-out;

      @media screen and (max-width: 768px){
        top: auto;
        bottom: -2px;
      }
    }
    
    #{$block}__item.state_active & {
      border-top: 2px solid $color-blue-main;
      font-weight: 800;

      @media screen and (max-width: 768px){
        border-top: none;
        border-bottom: 2px solid #c0c3c4;
      }
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
}

.headerHamburger {
  $block: &;
  display: flex;
  font-family: $Poppins !important;
  justify-content: flex-end;
  width: 50px;
  max-width: 128px;
  position: relative;
  //margin-left: 100%;
  &__btn {
    font-family: $Poppins !important;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 24px;
    height: 18px;
    background: transparent;
    cursor: pointer;

    &.state_active {
      &:before {
        top: 8px;
        transform: rotate(45deg);
      }
      &:after {
        bottom: 8px;
        transform: rotate(-45deg);
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background: $color-white-simple;
      transition: .4s;
    }
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background: $color-white-simple;
      transition: .4s;
    }
    
    &Line {
      display: block;
      align-self: flex-end;
      width: 20px;
      height: 2px;
      border-radius: 1px;
      background: $color-white-simple;
      transition: .4s;

      #{$block}__btn.state_active & {
        background: transparent;
      }
      
      #{$block}__btn:hover & {
        width: 100%;
      }
    }
  }

  &__menu {
    font-family: $Poppins !important;
    position: absolute;
    bottom: -110px;
    color: #1e96fb;
    padding: 10px;
    background: #fff;
    box-shadow: 30px 35px 30px 40px rgb(0 0 0 / 20%);
    transition: 0.4s ease-in-out;

    @media screen and (max-width: 768px){
      display: none;
    }

    button {
      white-space: nowrap;
    }

    &__logoutIcon {
      margin-left: -15px;
      margin-top: -3px;
    }
  }
}