.text {
  text-align: center;
}

.link {
  padding-top: 30px;
  text-align: center;

  a {
    font-size: 18px;
    font-weight: 600;
  }
}

.notFound {
  margin-bottom: 30px;
  font-size: 48px;
}

