@import "../../../assets/styles/variables";

.desc {
  margin-top: 30px;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 30px;
  color: $color-text;

  &Text {
    margin-top: 30px;
  }

  &Header {
    margin-top: 30px;
    font-weight: 600;
  }

  &List {
    margin: 10px 0 0 15px;
    padding: 0;
    width: 700px;
    columns: 2;

    &>li {
      margin: 0;
      padding: 0 0 5px;
      width: 350px;
    }

    @media screen and (max-width: 700px){
      columns: 1;
    }
  }
}

.affiliates {
  margin-top: 65px;

  &Card {
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0 50px 70px 50px;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
    width: 100%;

    &.odd {
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 900px){
      flex-wrap: wrap;
      justify-content: center;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &Img {
      padding: 0;
      margin: 0;
      display: flex;
      height: 318px;
    }

    &Desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      &Header {
        font-weight: 700;
        padding-bottom: 5px;
      }

      &Text {
        text-align: justify;
      }

      &Code {
        margin-top: 20px;
        font-weight: 700;
        &>span {
          font-weight: normal;
        }
      }

      &Contact {
        margin-top: 10px;
        margin-bottom: 5px;
      }

      &Contacts {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @media screen and (max-width: 1078px){
          &>.affiliatesCardDescContact {
            display: none;
          }
        }
        &Col:first-child {
          margin-right: 5px;
        }
      }

      &ContactLine {
        display: block;
        font-weight: 700;
        &>span {
          font-weight: 400;
        }
      }

      &Text {
        &>p {
          margin-top: 8px;
        }
      }

      &Btns {
        margin-top: 15px;
        display: flex;
        gap: 30px;
      }
    }
  }
}

.affiliateBtn {
  height: 38px;
  width: 200px;
  font: 700 13px $Poppins !important;
  //font-size: 13px !important;
  //font-weight: 700 !important;
  line-height: 15px !important;

  &.grey {
    color: $color-gray;
    width: 180px;
    border-radius: 28px !important;
    border: 2px solid $color-gray !important;
    background: transparent !important;

    &:before {
      content: url('../../../assets/images/icons/copy.svg');
      padding-right: 10px;
      margin-top: 3px;
      vertical-align: -70%;
    }
  }
}

.MuiTooltip-tooltipPlacementBottom {
  margin-top: 10px !important;
}