@import "../../../assets/styles/variables";

.footerContainer {
  background: $color-footer;
  color: white;
  text-align: center;
  padding-top: 20px;
  font: 600 12px $Poppins;
  text-transform: uppercase;
}

.footerList {
  margin-top: 10px;
  margin-bottom: 20px;

  &Link {
    color: white;

    &:visited {
      color: white;
    }
  }

  & ul {
    display:inline-table;
  }

  & li {
    margin-left: 0;
    margin-right: 10px;
    display:inline;
    color: white;

    &:not(:first-child):before {
      margin-right: 7px;
      content: '';
      display: inline-block;
      vertical-align:middle;
      width: 5px;
      height: 5px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background-color: white;
    }
  }
}


//.footerView {
//  padding: 20px 20px;
//  margin: 0 auto;
//  display: block;
//  text-align: center;
//  align-items: center;
//  justify-content: flex-end;
//  gap: 20px;
//  width: 100%;
//  max-width: 1264px;
//  box-sizing: border-box;
//  color: white;
//
//  @media screen and (max-width: 768px){
//    padding: 12px 16px;
//  }
//  @media screen and (max-width: 400px){
//    flex-direction: column;
//  }
//
//  .footerNavigation {
//    padding: 0;
//    margin: 0;
//    list-style: none;
//    display: flex;
//    align-items: center;
//
//    @media screen and (max-width: 400px){
//      flex-direction: column;
//    }
//
//    &__item {
//      display: flex;
//      align-items: center;
//
//      &:last-of-type {
//        &:after {
//          display: none;
//        }
//      }
//
//      &:after {
//        font: 400 14px/24px $Poppins;
//        color: #E5E5E5;
//        margin: 0 12px;
//        content: '/';
//        display: block;
//
//        @media screen and (max-width: 400px){
//          display: none;
//        }
//      }
//
//      &.state_active {
//        pointer-events: none;
//        .footerNavigation__link {
//          font-weight: 600;
//          color: #fff;
//        }
//      }
//    }
//
//    &__link {
//      font: 400 14px/24px $Poppins;
//      color: #E5E5E5;
//
//      @media screen and (max-width: 400px){
//        font-size: 16px;
//        line-height: 28px;
//      }
//    }
//  }
//
//
//
//  .footerLogo {
//    display: block;
//    width: 100%;
//    max-width: 106px;
//
//    &__img {
//      display: block;
//      width: 100%;
//    }
//  }
//}