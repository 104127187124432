@import "../../../assets/styles/variables";

.spinner {
  top: 200px;
}

.BackBtn {
  font: 600 14px $Poppins !important;
  max-width: 100px;
  min-width: 100px;
  height: 35px;
  margin-left: 40px !important;
  background-color: $color-blue-main !important;
}

.Library {
  &Description {
    margin: 20px 0 20px 0;
    font: 400 16px $Poppins !important;
    &Text {
      margin-top: 10px;
    }
  }

  //&Categories {
  //  margin-top: 50px;
  //  display: flex;
  //  flex-direction: row;
  //  flex-wrap: wrap;
  //  flex-flow: row wrap;
  //  justify-content: flex-start;
  //}

  &Categories {
    margin-top: 50px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fill, 260px);
    grid-auto-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
}

.CategoryCard {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  position: relative;
  padding: 20px 20px 20px 20px;
  background: #F5F5F5;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.45);
  width: 245px;
  min-height: 190px;
  border-radius: 12px;

  &>h2 {
    font-family: $Montserrat;
    font-weight: 900;
    font-size: 16px;
  }

  &>h4 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: $OpenSans;
    font-size: 10px;
  }

  &>hr {
    margin-top: 10px;
    height: 4px;
    border: none;
    background-color: $color-yellow;
  }

  &Link {
    font: 600 12px $Poppins !important;
    width: 100%;
    height: 40px !important;
    margin-top:auto;

    &Btn {
      font: 600 12px $Poppins !important;
      width: 100%;
      height: 100%;
    }
  }
}


.Category {

  &Tabs{
    margin: 50px 0 0 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row wrap;
    text-transform: uppercase;
    font: 600 14px $Poppins !important;
    width: auto;
  }

  &Tab{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px 0 30px;
    min-width: 50px;
    height: 40px;
    margin: 0 10px 10px 0;
    background-color: #e5e5e5;
    border-radius: 5px;
    color: $color-black;
    //flex-grow: 1;

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }

    &.-active {
      background-color: $color-blue-main;
      color: $color-white-simple;
    }
  }


  &Table {
    margin-top: 40px;
    width: 100%;
    border-collapse: collapse;

    thead {
      font: 700 16px $Poppins !important;
      border-bottom: 2px solid black;
      td {
        padding-bottom:10px;
      }
      td:first-child {
        padding-left: 20px;
      }
      td:last-child,:nth-last-child(2) {
        //border: 1px solid red;
        text-align: center;
      }
    }

    tbody {
      tr {
        //height: 50px;
        &.-even {
          background-color: #f5f5f5;
        }
        td:first-child {
          line-height: 25px;
          padding: 15px 0 15px 20px;
        }
        td:last-child,:nth-last-child(2) {
          text-align: center;
        }
      }
    }

  }

  &Icon:hover {
    transform: scale(1.25);
  }
}

