@import "../../../assets/styles/variables";

.paginationTable {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (max-width: 768px){
    margin-top: 16px;
    flex-direction: column;
    gap: 23px;
  }

  &__countPerPage {
    font: 400 italic 14px/20px $Poppins;
    color: $color-black-simple;
  }

  .MuiButtonBase-root {
    font: 600 14px/20px $Poppins;
    color: $color-black-simple;
    margin: 0;
    border: 1px solid $color-black-simple;
    border-radius: 50% !important;

    &:hover {
      background: lighten($color-blue-main, 40%);
    }

    &.Mui-selected {
      color: #fff;
      background: $color-black-simple;
      border-color: $color-black-simple;
    }
  }

  .MuiPagination-ul {
    gap: 4px;

    @media screen and (max-width: 374px){
      gap: 3px;
    }
    
    li {
      svg {
        display: none;
      }
      &:first-of-type {
        margin-right: 8px;

        @media screen and (max-width: 374px){
          margin: 0;
        }

        .MuiButtonBase-root {
          font: 600 12px/16px $Poppins;
          color: $color-black-simple;
          text-transform: uppercase;
          padding-left: 40px;
          padding-right: 40px;
          border-radius: 28px !important;

          @media screen and (max-width: 768px){
            padding-left: 18px;
            padding-right: 18px;
          }
          @media screen and (max-width: 374px){
            padding-left: 10px;
            padding-right: 10px;
          }

          &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url("../../../assets/images/icons/pagination-arrow-left_ic.svg") center no-repeat;

            @media screen and (max-width: 768px){
              display: none;
            }
          }
          &:after {
            margin-left: 6px;
            content: 'prev';

            @media screen and (max-width: 768px){
              margin: 0;
            }
          }
        }
      }
      &:last-of-type {
        margin-left: 8px;

        @media screen and (max-width: 374px){
          margin: 0;
        }
        
        .MuiButtonBase-root {
          font: 600 12px/16px $Poppins;
          color: $color-black-simple;
          text-transform: uppercase;
          padding-left: 40px;
          padding-right: 40px;
          border-radius: 28px !important;

          @media screen and (max-width: 768px){
            padding-left: 18px;
            padding-right: 18px;
          }
          @media screen and (max-width: 374px){
            padding-left: 10px;
            padding-right: 10px;
          }

          &:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: url("../../../assets/images/icons/pagination-arrow-right_ic.svg") center no-repeat;

            @media screen and (max-width: 768px){
              display: none;
            }
          }
          &:before {
            margin-right: 6px;
            content: 'Next';

            @media screen and (max-width: 768px){
              margin: 0;
            }
          }
        }
      }
    }
  }
  .MuiPaginationItem-previousNext {
    svg {
      @media screen and (max-width: 768px){
        display: none;
      }
    }
  }
}
