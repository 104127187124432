/* Fonts */
$OpenSans: 'Open Sans', sans-serif;
$Poppins: 'Poppins', sans-serif;
$Montserrat: 'Montserrat', sans-serif;

/* Colors */
$color-black-simple: #000;
$color-white-simple: #fff;
$color-blue-main: #1E96FC;
$color-yellow: #FCA311;
$color-gray: #6C757D;
$color-black: #14213D;
$color-green: #0ef106;
$color-red: red;
$color-error: red;
$color-vinous: #CD428A;
$color-footer: #203542;
$color-text: #333333;