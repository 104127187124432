.header {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
  border: 1px solid #e1e1e1;
  padding-left: 30px;
  position: relative;

  h2 {
    font-size: 24px;
    color: #232e3f;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px;

  @media screen and (max-width: 480px) {
    padding: 30px;
  }
  @media screen and (max-width: 375px) {
    padding: 40px 20px;
  }
}

.closeBtn {
  background-color: transparent;
  padding: 5px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.crossIcon {
  width: 20px;
  height: 20px;
}

.text {
  color: #111111;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: center;
  white-space: pre-line;
}

.actions {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & button {
    width: 200px;
  }
  .OKButton {
    padding-left: 25px;
  }

  .cancelButton {
    margin-left: auto;
    padding-right: 25px;
  }

  @media screen and (max-width: 769px) {
    display: block;
    text-align: center;
    & button {
      margin-bottom: 15px;
      width: 300px;
    }
    .OKButton, .cancelButton {
      padding: 0;
    }
  }
}

