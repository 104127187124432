@import "../../../assets/styles/variables";

.stripeInputField {
  $block: &;
  width: 100%;

  &.-linkCopy {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__title {
    font: 700 14px/24px $Poppins;
    color: $color-black;
    text-transform: capitalize;
  }

  &__input {
    padding: 16px 20px;
    border: 1px solid #000000;
    background: #FFFFFF;
    height: 56px;

    @media screen and (max-width: 768px){
      padding: 12px 16px;
    }

    #{$block}.-linkCopy & {
      width: 100%;
    }

    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }

    &.error {
      border-color: $color-error;
    }
  }
}

.terms {
  margin-left: -9px !important;
  margin-top: 20px;
}